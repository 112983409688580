const PrivacyPage = () => {
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <h2 style={{ textAlign: "center" }}>QUY ĐỊNH CHUNG</h2>
      <ol>
        <li>
          Chấp thuận các Điều kiện Sử dụng:
          <p>
            Khi sử dụng ứng dụng TanvanKids của Hệ thống Giáo dục Tân Văn, người
            dùng đã mặc nhiên chấp thuận các điều khoản và điều kiện sử dụng
            được quy định dưới đây. Để biết được các sửa đổi mới nhất, người
            dùng nên thường xuyên kiểm tra lại “Điều kiện sử dụng”. Hệ thống
            Giáo dục Tân Văn có quyền thay đổi, điều chỉnh, thêm hay bớt các nội
            dung của “Điều kiện sử dụng” tại bất kỳ thời điểm nào. Nếu người
            dùng vẫn tiếp tục sử dụng ứng dụng sau khi có các thay đổi như vậy
            thì có nghĩa là người dùng đã chấp thuận các thay đổi đó.
          </p>
        </li>
        <li>Tính chất hiển thị thông tin:</li>
        <ol type="a">
          <li>
            Ứng dụng TanvanKids thuộc sở hữu hợp pháp của CÔNG TY TNHH MỘT THÀNH
            VIÊN TƯ VẤN DỊCH VỤ GIÁO DỤC VÀ DU LỊCH TÂN VĂN.
          </li>
          <li>
            Quý thầy cô, quý phụ huynh và các em học sinh tham khảo các khoá học
            và tài liệu tại website tanvan.edu.vn trên cơ sở tôn trọng quyền và
            lợi ích hợp pháp của các bên tham gia thực hiện đúng với quy chế và
            các quy định của pháp luật liên quan.
          </li>
          <li>
            Hoạt động đăng ký ứng dụng phải được thực hiện công khai, minh bạch,
            đảm bảo quyền lợi của người sử dụng dịch vụ
          </li>
          <li>
            Bản quy định chung này áp dụng cho tất cả các người dùng tham gia
            kích hoạt ứng dụng trên CHplay hoặc Appstore.
          </li>
          <li>
            Tất cả các nội dung trong bản quy chế này phải tuân thủ theo hệ
            thống pháp luật hiện hành của Việt Nam.
          </li>
        </ol>
        <li>
          Quy định về quyền lợi và nghĩa vụ của người dùng:
          <ul type="none">
            <li>
              3.1. Quy định về tài khoản và quyền lợi bảo mật thông tin của
              người dùng
              <ul type="none">
                <li>
                  3.1.1. Quy định về tài khoản
                  <p>
                    Khi sử dụng ứng dụng TanvanKids, người dùng sẽ cung cấp cho
                    chúng tôi thông tin về địa chỉ email, mật khẩu và thông tin
                    cá nhân để có được 01 tài khoản để kích hoạt. Việc sử dụng
                    và bảo mật thông tin tài khoản là trách nhiệm và quyền lợi
                    của người dùng khi sử dụng ứng dụng TanvanKids. Ngoài ra,
                    những thông tin khác trong tài khoản như: họ tên, địa chỉ,
                    điện thoại.... là những thông tin của người dùng giúp cho
                    ứng dụng TanvanKids phục vụ người dùng tốt nhất.
                  </p>
                  <p>
                    Trong trường hợp thông tin do người dùng cung cấp không đầy
                    đủ hoặc sai dẫn đến việc chúng tôi không đủ cơ sở để thực
                    hiện kích hoạt tài khoản cho người dùng, chúng tôi có quyền
                    từ chối cung cấp dịch vụ mà không phải chịu bất cứ trách
                    nhiệm nào đối với người dùng. Khi có những thay đổi thông
                    tin cá nhân của người dùng, quý thầy cô và quý phụ huynh vui
                    lòng đăng nhập hệ thống sau đó cập nhật lại thông tin trong
                    tài khoản tại ứng dụng TanvanKids.
                  </p>
                  <p>
                    Người dùng phải giữ kín mật khẩu và tài khoản, người dùng
                    phải hoàn toàn chịu trách nhiệm đối với tất cả các hoạt động
                    diễn ra thông qua việc sử dụng mật khẩu hoặc tài khoản của
                    mình. Người dùng đảm bảo thoát khỏi tài khoản ứng dụng
                    TanvanKids sau mỗi lần sử dụng để bảo mật thông tin của mình
                    an toàn. Trong trường hợp nghi ngờ tài khoản của mình bị
                    truy cập trái phép, quý thầy cô, quý phụ huynh và các em học
                    sinh phải nhanh chóng thông báo cho chúng tôi để ngăn chặn
                    kịp thời. Chúng tôi không chịu bất kỳ trách nhiệm nào, dù
                    trực tiếp hay gián tiếp, đối với những thiệt hại hoặc mất
                    mát gây ra do quý thầy cô, quý phụ huynh và các em học sinh
                    không tuân thủ quy định.
                  </p>
                </li>
                <li>
                  3.1.2. Quyền lợi bảo mật thông tin của người dùng
                  <p>
                    Quý thầy cô, quý phụ huynh và các em học sinh có thể hoàn
                    toàn yên tâm về các thông tin của mình trên ứng dụng
                    TanvanKids. Khi sử dụng dịch vụ của chúng tôi, người dùng
                    được đảm bảo những thông tin cá nhân mà người dùng cung cấp
                    cho chúng tôi sẽ chỉ được dùng để nâng cao chất lượng dịch
                    vụ chăm sóc người dùng của ứng dụng TanvanKids và sẽ không
                    được thông tin hay chuyển giao cho một bên thứ ba nào khác
                    vì bất kỳ mục đích nào. Thông tin của người dùng tại ứng
                    dụng TanvanKids sẽ được chúng tôi bảo mật và chỉ trong
                    trường hợp pháp luật yêu cầu, chúng tôi mới cung cấp những
                    thông tin này cho các cơ quan pháp luật. Quý thầy cô và quý
                    phụ huynh vui lòng tham khảo thêm quyền lợi bảo mật thông
                    tin của người dùng chi tiết tại mục “Chính sách bảo mật”.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              3.2. Quyền và nghĩa vụ của người dùng khi sử dụng ứng dụng
              TanvanKids.
              <ul type="none">
                <li>
                  3.2.1. Quyền của người dùng
                  <ul type="">
                    <li>
                      Được tiếp cận, lựa chọn các kích hoạt các loại tài liệu
                      trên ứng dụng TanvanKids.
                    </li>
                    <li>
                      Được hưởng các hỗ trợ của ứng dụng TanvanKids và các bộ
                      phận hỗ trợ khác để việc tìm hiểu thông tin được thuận
                      lợi.
                    </li>
                    <li>Khiếu nại nếu có sai sót trong việc sử dụng dịch vụ</li>
                  </ul>
                </li>
                <li>
                  3.2.2. Nghĩa vụ của người dùng
                  <ul>
                    <li>
                      Cung cấp đầy đủ thông tin cá nhân có liên quan khi đăng ký
                      là thành viên như: Họ và tên, địa chỉ liên lạc, email, số
                      căn cước công dân, điện thoại, số tài khoản, số thẻ thanh
                      toán..., và chịu trách nhiệm về tính pháp lý của những
                      thông tin trên. Ban quản lý ứng dụng TanvanKids không chịu
                      trách nhiệm cũng như không giải quyết mọi khiếu nại có
                      liên quan đến quyền lợi của người dùng nếu xét thấy tất cả
                      thông tin cá nhân của người dùng cung cấp khi đăng ký ban
                      đầu là không chính xác.
                    </li>
                    <li>
                      Người dùng tuyệt đối không sử dụng bất kỳ chương trình,
                      công cụ hay hình thức nào khác để can thiệp vào hệ thống
                      hay làm thay đổi cấu trúc dữ liệu. Nghiêm cấm việc phát
                      tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can
                      thiệp, phá hoại hay xâm của hệ thống website. Mọi vi phạm
                      sẽ bị xử lý theo Quy chế và quy định của pháp luật.
                    </li>
                    <li>
                      Người dùng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ và
                      mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu
                      của mình. Người dùng có thể sửa dữ liệu cá nhân của mình
                      trên ứng dụng TanvanKids. Người dùng có trách nhiệm thông
                      báo kịp thời cho ban quản lý ứng dụng TanvanKids về những
                      hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu
                      giữ tên đăng ký và mật khẩu của mình để hai bên cùng hợp
                      tác xử lý.
                    </li>
                    <li>
                      Người dùng cam kết những thông tin cung cấp cho ứng dụng
                      TanvanKids và những thông tin đăng tải lên ứng dụng
                      TanvanKids là chính xác và hoàn chỉnh (đặc biệt thông tin
                      có liên quan đến cá nhân của người dùng). Người dùng đồng
                      ý giữ và thay đổi các thông tin trên ứng dụng TanvanKids
                      là cập nhật, chính xác và hoàn chỉnh.
                    </li>
                  </ul>
                </li>
                <li>
                  3.2.2. Nghĩa vụ của người dùng
                  <ul>
                    <li>
                      Người dùng cam kết, đồng ý không sử dụng dịch vụ ứng dụng
                      TanvanKids vào những mục đích bất hợp pháp, không hợp lý,
                      lừa đảo, đe doạ, thăm dò thông tin bất hợp pháp, phá hoại,
                      tạo ra và phát tán virus gây hư hại tới hệ thống, cấu
                      hình, truyền tải thông tin của ứng dụng TanvanKids hay sử
                      dụng dịch vụ của mình vào mục đích phá hoại, tạo những
                      cuộc hẹn giả nhằm mục đích xấu. Trong trường hợp vi phạm
                      thì thành viên phải chịu trách nhiệm về các hành vi của
                      mình trước pháp luật.
                    </li>
                    <li>
                      Người dùng cam kết không được thay đổi, chỉnh sửa, gán
                      ghép, copy, truyền bá, phân phối, cung cấp và tạo những
                      công cụ tương tự của dịch vụ do ứng dụng TanvanKids cung
                      cấp cho một bên thứ ba nếu không được sự đồng ý của ứng
                      dụng TanvanKids trong bản Điều khoản này.
                    </li>
                    <li>
                      Người dùng không được hành động gây mất uy tín của ứng
                      dụng TanvanKids dưới mọi hình thức như gây mất đoàn kết
                      giữa các thành viên bằng cách sử dụng tên đăng ký thứ hai,
                      thông qua một bên thứ ba hoặc tuyên truyền, phổ biến những
                      thông tin không có lợi cho uy tín của ứng dụng TanvanKids.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Quyền và nghĩa vụ của ứng dụng TanvanKids
          <ul type="none">
            <li>
              4.1. Quyền của Ban quản lý ứng dụng TanvanKids:
              <ul>
                <li>
                  Được phép đăng tải các thông tin giới thiệu và xúc tiến nhằm
                  đạt được mục tiêu chung của dự án.
                </li>
                <li>
                  Không cho phép các tổ chức, cá nhân khác được phép đăng tải,
                  quảng bá sản phẩm tại ứng dụng TanvanKids.
                </li>
                <li>
                  Có quyền thay đổi “Điều khoản sử dụng”, chính sách bảo mật và
                  các chính sách khác...Trước khi thay đổi Ban quản lý sẽ thông
                  báo tới người sử dụng trước ít nhất 07 ngày.
                </li>
              </ul>
            </li>
            <li>
              4.2. Nghĩa vụ của Ban quản lý ứng dụng TanvanKids
              <ul>
                <li>Đảm bảo sự ổn định của ứng dụng TanvanKids.</li>
                <li>
                  Cập nhật thông tin đầy đủ và kịp thời về các quy định, chính
                  sách của ứng dụng TanvanKids, các gói dịch vụ trên ứng dụng
                  TanvanKids.
                </li>
                <li>
                  Đảm bảo thông tin đăng tải trên ứng dụng TanvanKids phù hợp
                  với quy định pháp luật, thuần phong mỹ tục; đảm bảo các dịch
                  vụ không vi phạm các quy định của pháp luật.
                </li>
                <li>
                  Có các quy định, hướng dẫn chi tiết cho người dùng về cách
                  thức tham gia khóa học.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Quy định về “Miễn trừ trách nhiệm và Quyền sở hữu trí tuệ”:
          <ul type="none">
            <li>
              5.1. Các quy định về việc Miễn trừ trách nhiệm
              <ul>
                <li>
                  Thông tin hiển thị tại ứng dụng TanvanKids này không đi kèm
                  bất kỳ đảm bảo hay cam kết trách nhiệm từ phía Hệ thống giáo
                  dục Tân Văn về sự phù hợp của các tài liệu người dùng đã mua
                  do các nhà xuất bản khác và từ chối trách nhiệm hay đưa ra đảm
                  bảo rằng ứng dụng TanvanKids sẽ không có lỗi vận hành, an
                  toàn, không bị gián đoạn hay tính chính xác, đầy đủ và đúng
                  hạn của các thông tin hiển thị.
                </li>
                <li>
                  Khi truy cập vào ứng dụng TanvanKids này, người dùng mặc nhiên
                  đồng ý rằng các đơn vị trường phối hợp với Hệ thống Giáo dục
                  Tân Văn thực hiện chương trình cho trẻ mẫu giáo Làm quen tiếng
                  Anh không chịu bất cứ trách nhiệm nào liên quan đến thương
                  tật, mất mát, khiếu kiện, thiệt hại trực tiếp hoặc gián tiếp
                  do không lường trước hoặc do hậu quả để lại dưới bất cứ hình
                  thức nào phát sinh từ việc:
                  <ul type="none">
                    <li>
                      (1) Sử dụng các thông tin trên ứng dụng TanvanKids này;
                    </li>
                    <li>
                      (2) Các truy cập kết nối từ ứng dụng TanvanKids này;
                    </li>
                    <li>
                      (3) Đăng ký thành viên, sử dụng kích hoạt sách tại ứng
                      dụng TanvanKids này Các điều kiện và hạn chế nêu trên chỉ
                      có hiệu lực trong khuôn khổ pháp luật hiện hành.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              5.2. Quyền sở hữu trí tuệ
              <p>
                Ứng dụng TanvanKids này và mọi nội dung xếp đặt, hiển thị đều
                thuộc sở hữu và là tài sản độc quyền khai thác của Hệ thống Giáo
                dục Tân Văn. Mọi sử dụng, trích dẫn phải không gây thiệt hại cho
                Hệ thống Giáo dục Tân Văn và đều phải tuân thủ các điều kiện
                sau:
                <ul type="none">
                  <li>(1) Chỉ sử dụng cho mục đích cá nhân, phi thương mại;</li>
                  <li>
                    (2) Các sao chép hoặc trích dẫn đều phải giữ nguyên dấu hiệu
                    bản quyền hoặc các yết thị về quyền sở hữu trí tuệ như đã
                    thể hiện trong phiên bản gốc; và tất cả các nội dung được
                    cung cấp tại ứng dụng TanvanKids này không được phép nhân
                    bản, hiển thị, công bố, phổ biến, đưa tin tức hay lưu hành
                    cho bất cứ ai, dưới bất kỳ hình thức nào, kể cả trên các
                    Website độc lập khác mà không được sự chấp thuận của Hệ
                    thống Giáo dục Tân Văn.
                  </li>
                </ul>
              </p>
            </li>
          </ul>
        </li>
        <li>
          Điều chỉnh và sửa đổi:
          <p>
            Hệ thống Giáo dục Tân Văn bảo lưu quyền thay đổi, chỉnh sửa hoặc
            chấm dứt hoạt động của ứng dụng TanvanKids này vào bất cứ thời điểm
            nào.
          </p>
        </li>
        <li>
          Giải quyết khiếu nại, tranh chấp:
          <ul>
            <li>
              Tranh chấp giữa ứng dụng TanvanKids với người dùng trong quá trình
              thực hiện hợp đồng phải được giải quyết trên cơ sở các điều khoản
              của hợp đồng công bố tại ứng dụng TanvanKids vào thời điểm giao
              kết hợp đồng và quy định của pháp luật có liên quan.
            </li>
            <li>
              Việc giải quyết tranh chấp phải thông qua thương lượng giữa các
              bên, hòa giải, trọng tài hoặc tòa án theo các thủ tục, quy định
              hiện hành về giải quyết tranh chấp.
            </li>
            <li>
              Giải quyết khiếu nại, tranh chấp trên ứng dụng TanvanKids: Người
              dùng cần phải thường xuyên đọc và tuân theo các Chính sách và Quy
              định đang được đăng trên web để có thể hiểu và thực hiện được các
              Chính sách và Quy định tại thời điểm đó.
            </li>
            <li>
              Trong quá trình sử dụng ứng dụng, nếu có bất kì sự cố nào liên
              quan đến xác nhận thông tin thanh toán. Người dùng sẽ liên hệ trực
              tiếp tới bộ phận hỗ trợ người dùng để được giải quyết một cách
              nhanh chóng và kịp thời. Khi có bất kì tranh chấp nào khác phát
              sinh trong quá trình giao dịch trên ứng dụng TanvanKids và các bên
              có liên quan như thương nhân cung cấp dịch vụ ngân hàng cung cấp
              dịch vụ thanh toán và người dùng sử dụng dịch vụ sẽ cử đại diện
              tham gia vào quá trình giải quyết.
            </li>
            <li>
              Khi có bất kỳ khiếu nại nào của người dùng, vui lòng thông báo cho
              ứng dụng TanvanKids. Ứng dụng TanvanKids sẽ tiến hành xác minh và
              giải quyết các khiếu nại trong thời gian 07 ngày.
            </li>
            <li>
              Các bên phải thực hiện đúng trách nhiệm, quyền và nghĩa vụ của
              mình trong quá trình giải quyết tranh chấp. Nếu có thiệt hại xảy
              ra, các bên sẽ thỏa thuận cùng nhau trên tinh thần hợp tác để tìm
              ra biện pháp giải quyết hợp lý nhất. Nếu không đi đến được một sự
              thống nhất chung về ý chí trong quá trình giải quyết tranh chấp,
              thì sẽ đưa vụ việc tranh chấp ra cơ quan có thẩm quyền giải quyết.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPage;
