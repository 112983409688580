import Homepage from "./home";
import { Routes, Route } from "react-router-dom";
import PrivacyPage from "./privacy";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
    </Routes>
  );
}

export default App;
